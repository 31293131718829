.cds-icon-input {
  padding-right: 16px;
  cursor: pointer;

  input {
    padding-right: 8px;
  }

  cds-svg-icon {
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover svg{
      background: var(--accent-palette-800);
    }
  }
}

.input-error:not(.ng-pristine, .ng-untouched) {
  border-color: red;
  border-width: 1px;
}
