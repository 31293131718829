svg {
  fill: currentColor;
  stroke: currentColor;

  g, path {
    stroke: currentColor;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
