.cds-button-icon {
  padding: 8px;
  border-radius: 8px;

  &:hover {
    background: var(--primary-palette-500);

    cds-svg-icon {
      color: var(--primary-palette-contrast-500);
    }
  }

  cds-svg-icon {
    color: var(--accent-palette-200)
  }

}
