.chart-container {
  background: var(--accent-palette-300);
  background: linear-gradient(45deg, var(--accent-palette-300) 50%, var(--primary-palette-500) 200%);
  border-radius: 32px;
  padding: 24px;
  display: flex;
}

.chart-message {
  color: var(--accent-palette-contrast-500);
  font-size: larger;
}
