@use "../utils/effects";

.cds-select {
  outline: none;
  appearance: none;

  background: var(--accent-palette-400) !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 6'%3E%3Cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 1 4 4 4-4'/%3E%3C/svg%3E") !important;
  padding: 0 2.5rem 0 16px;
  -webkit-print-color-adjust: exact;
  background-position: right 0.75rem center !important;
  background-repeat: no-repeat !important;
  background-size: 0.75em 0.75em !important;
  color: var(--accent-palette-contrast-400);

  &:focus {
    @include effects.halo-effect
  }
}

.dropdown {

  &:focus {
    @include effects.halo-effect
  }

  & + div:not(#dropdown-fadeout), & + div#dropdown-fadeout > div {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: var(--accent-palette-500);
    box-shadow: 0 0 8px var(--accent-palette-100);
    width: 100%;
    max-height: 400px;
    overflow: auto;
    border-radius: 10px;
    top: calc(100% + 8px);
    left: 0;
    z-index: 100;

    & > div {
      padding: 1rem;
      cursor: pointer;

      &:hover {
        background: var(--accent-palette-400);
      }
    }
  }
}
