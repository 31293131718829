@use "components/charts";
@use "components/select";
@use "components/buttons";
@use "components/inputs";
@use "vendor/ng-dragula";
@use "utils/animations";
@forward "utils/effects";
@use "base/reset";

h1 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: normal;
}

h3 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 115%;
  letter-spacing: normal;
}

.colored-theme {

  color: var(--accent-palette-contrast-500);

  strong {
    color: var(--primary-palette-500);
  }

  .accent-background-300 {
    background: var(--accent-palette-300);
  }
}
