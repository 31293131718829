// project styles import
@use "assets/scss/main";

@use 'ngx-toastr/toastr';

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'MyFont', sans-serif;
}

.colored-theme {

  /////////////////////////////////////
  /////////// COLORED THEME ///////////
  /////////////////////////////////////

  html, body, app-root {
    background: var(--accent-palette-500)
  }
}

/////////////////////////////////////
/////////// LIGHT THEME /////////////
/////////////////////////////////////

.light-theme {
  html, body, app-root {
    background: white;
  }
}
